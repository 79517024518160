<template>
  <!-- eslint-disable vue/camelcase -->
  <div class="settingsPage">
    <div v-if="user && isMounted" class="row">
      <div class="col-12">
        <div class="tabs">
          <b-nav tabs>
            <b-nav-item to="#" :active="$route.hash === '#' || $route.hash === ''">
              Settings
            </b-nav-item>
            <b-nav-item v-if="user.has_review_requester" to="#texts" :active="$route.hash === '#texts'">
              Email/SMS Review Request Settings
            </b-nav-item>
            <b-nav-item to="#emails" :active="$route.hash === '#emails'">
              Emails
            </b-nav-item>
            <b-nav-item v-if="!isZenSocial" to="#smtp" :active="$route.hash === '#smtp'">
              SMTP
            </b-nav-item>
            <b-nav-item to="#privacy" :active="$route.hash === '#privacy'">
              Privacy
            </b-nav-item>
            <b-nav-item to="#billing" :active="$route.hash === '#billing'">
              Billing
            </b-nav-item>
            <b-nav-item v-if="false && hasPremiumPlan" to="#limits" :active="$route.hash === '#limits'">
              Business Limits
            </b-nav-item>
          </b-nav>
          <div class="tab-content">
            <div :class="['tab-pane', { 'active': $route.hash === '#' || $route.hash === '' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="updateForm"
                  >
                    <form
                        class="settings-form"
                        @submit.prevent="handleSubmit(updateProfile)"
                    >
                      <div class="row">
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.first_name"
                              name="first_name"
                              rules="required"
                              label="First Name"
                          />
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.last_name"
                              name="last_name"
                              rules="required"
                              label="Last Name"
                          />
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <label>Phone Number</label>
                          <VuePhoneNumberInput v-model="user.phone_number" :error="phoneError" valid-color="#e2e7f1" @update="setPhone" />

                        </div>
                        <div class="col-md-6 col-lg-3">
                          <TextInput
                              v-model="user.email"
                              name="email"
                              label="Email Address"
                              rules="required|email"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-md-5">
                          <TextInput v-model="user.address.address" label="Address" type="text"  />
                        </div>
                        <div class="col-xl-3 col-md-4">
                          <TextInput v-model="user.address.city" label="City" type="text"  />
                        </div>
                        <div class="col-xl-1 col-md-3">
                          <TextInput v-model="user.address.state" label="State" type="text"  />
                        </div>
                        <div class="col-xl-2 col-md-4">
                          <TextInput v-model="user.address.zip" label="Zip" type="text"  />
                        </div>
                        <div class="col-xl-2 col-md-8">
                          <label class="form-control-label"> Country</label>
                          <select v-model="user.address.country" class="form-control">
                            <option v-for="country in Countries()" :key="country.id" :value="country.iso2">{{country.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-lg-6">
                          <TextInput
                              v-model="user.agency.name"
                              name="company_name"
                              rules="required"
                              :label="user.has_te ? 'Powered By Company Name' : 'Company name'"
                          />
                        </div>
                        <div v-if="user.has_te" class="col">
                          <TextInput v-model="user.agency.settings.powered_by_link" label="Leaderboard Powered By URL" />
                        </div>
                      </div>
                      <div v-if="user.has_re || user.has_social_review" class="row">
                        <div class="col">
                          <label class="d-block m-0">Notifications</label>
                        </div>
                      </div>
                      <div v-if="user.has_re || user.has_social_review" class="row">
                        <div class="col">
                          <div class="d-inline-block mr-3 mb-3">
                            <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                              <span v-if="user.agency.settings.notify_initial_searches">Enabled</span>
                              <span v-else>Disabled</span>
                            </small>
                            <div class="d-inline-flex">
                              <span class="mr-2 d-flex">Initial Searches</span>
                              <b-form-checkbox
                                  v-model="user.agency.settings.notify_initial_searches"
                                  size="sm"
                                  name="initial-button"
                                  switch
                                  inline
                              >
                              </b-form-checkbox>
                            </div>
                          </div>
                          <div class="d-inline-block" style="min-width: 171px; text-align: right">
                            <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                              <span v-if="user.agency.settings.notify_new_leads">Enabled</span>
                              <span v-else>Disabled</span>
                            </small>
                            <div class="d-inline-flex">
                              <span class="mr-2 d-flex">New Leads</span>
                              <b-form-checkbox
                                  v-model="user.agency.settings.notify_new_leads"
                                  size="sm"
                                  name="newl-button"
                                  switch
                                  inline
                              >
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="align-self-end text-right mt-md-0 mt-3 col-12">
                          <b-form-group>
                            <b-button
                                variant="primary"
                                class="btn-dark-blue"
                                :disabled="loadingUpdate || (invalid && touched)"
                                type="submit"
                            >
                              <b-spinner v-if="loadingUpdate" small />
                              <span v-else>Update</span>
                            </b-button>
                          </b-form-group>
                        </div>
                      </div>

                    </form>
                  </ValidationObserver>
                  <div v-if="user.has_re || user.has_social_review">
                    <WebHook />
                  </div>
                  <div v-if="user.has_lead_machine" class="mt-4">
                    <hr />
                    <h4>Lead Machine Webhook</h4>
                   <WebHook hooktype="leadmachine" />
                  </div>
                  <div v-if="user.tracking_numbers.length && false">
                    <h3>TapEngine Card Shipments</h3>
                    <table class="table border table-striped" style="max-width: 450px">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Tracking Information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(tracking,i) in user.tracking_numbers" :key="i">
                          <td>
                            {{tracking.front_date}}
                          </td>
                          <td>
                            <span v-if="tracking.tracking_number" class="text-success">Shipped</span>
                            <span v-else class="text-danger">Not Shipped</span>
                          </td>
                          <td>
                            <div v-if="tracking.tracking_number">
                              <div>Carrier: {{tracking.carrier}}</div>
                              <div>Tracking Number: <a :href="tracking.tracking_url">{{tracking.tracking_number}}</a></div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <PasswordReset />
              <ManageTeams v-if="(user.has_premium_plan)" />
              <div v-if="user.has_social_fusion" class="card">
                <div class="card-body">
                  <h4 class="font-weight-bold mb-4">OpenAi Configuration</h4>
                  <ValidationObserver v-slot="{ handleSubmit, invalid, touched }">
                    <form class="emails-form" @submit.prevent="handleSubmit(updateOpenAiAPIKey)">
                      <label>API Key</label>
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput v-if="aiDisabled" v-model="formattedAIPassword" disabled class="float-left pr-2"
                            style="width:calc(100% - 40px)" />
                          <TextInput v-else v-model="user.agency.settings.openai_api_key" class="float-left pr-2"
                            style="width:calc(100% - 40px)" />
                          <button type="button" class="btn btn-link p-0" @click="aiDisabled = !aiDisabled">
                            <Feather v-if="aiDisabled" type="edit" class="icon-lg" />
                            <Feather v-else type="x" class="icon-lg" />
                          </button>
                        </div>

                      </div>
                      <b-form-group class="mt-3">
                        <b-button variant="primary" class="btn-dark-blue" :disabled="aiBusy || (invalid && touched)"
                          type="submit">
                          <b-spinner v-if="aiBusy" small />
                          <span v-else>Update</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
              <div v-if="(user.has_premium_plan)" class="card">
                <div class="card-body">
                  <h4 class="font-weight-bold mb-1">White-label Domain</h4>
                  <WhiteLabelDomain />
                </div>
              </div>
            </div>
            <div v-if="user.has_review_requester" :class="['tab-pane', { 'active': $route.hash === '#texts' }]">
              <b-card no-body>
                  <b-tabs  card>
                    <b-tab title="API Settings">
                      <ValidationObserver
                          v-slot="{ handleSubmit, invalid, touched }"
                          ref="reportsForm"
                      >
                        <form
                            class="authentication-form"
                            @submit.prevent="handleSubmit(updateTwilioSettings)"
                        >
                          <div class="row">
                            <div class="col">
                              <label>Twilio From Number</label>
                              <VuePhoneNumberInput ref="vue_tel" v-model="user.agency.twilio_settings.twilio_number" :default-country-code="defaultCountryCode" class="" valid-color="#e2e7f1" @update="phoneUpdated" />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <label>Twilio SID</label>
                              <div class="row">
                                <div class="col">
                                  <TextInput
                                      v-if="isDisabled"
                                      v-model="formattedTwilioSID"
                                      disabled
                                      class="float-left pr-2"
                                      style="width:calc(100% - 40px)"
                                  />
                                  <TextInput
                                      v-else
                                      v-model="user.agency.twilio_settings.twilio_sid"
                                      class="float-left pr-2"
                                      style="width:calc(100% - 40px)"
                                  />
                                  <button type="button" class="btn btn-link p-0" @click="isDisabled = !isDisabled">
                                    <Feather v-if="isDisabled" type="edit" class="icon-lg" />
                                    <Feather v-else type="x" class="icon-lg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label>Twilio Token</label>
                              <div class="row">
                                <div class="col">
                                  <TextInput
                                      v-if="isDisabled"
                                      v-model="formattedTwilioToken"
                                      disabled
                                      class="float-left pr-2"
                                      style="width:calc(100% - 40px)"
                                  />
                                  <TextInput
                                      v-else
                                      v-model="user.agency.twilio_settings.twilio_token"
                                      class="float-left pr-2"
                                      style="width:calc(100% - 40px)"
                                  />
                                  <button type="button" class="btn btn-link p-0" @click="isDisabled = !isDisabled">
                                    <Feather v-if="isDisabled" type="edit" class="icon-lg" />
                                    <Feather v-else type="x" class="icon-lg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <TextInput v-model="user.agency.twilio_settings.sendgrid_email_address" type="email" name="Sendgrid Email" label="SendGrid Email Address" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label>SendGrid API Key</label>
                              <div class="row">
                                <div class="col">
                                  <TextInput
                                      v-if="isDisabled"
                                      v-model="formattedSengridApiKey"
                                      disabled
                                      class="float-left pr-2"
                                      style="width:calc(100% - 40px)"
                                  />
                                  <TextInput
                                      v-else
                                      v-model="user.agency.twilio_settings.sendgrid_api_key"
                                      class="float-left pr-2"
                                      style="width:calc(100% - 40px)"
                                  />
                                  <button type="button" class="btn btn-link p-0" @click="isDisabled = !isDisabled">
                                    <Feather v-if="isDisabled" type="edit" class="icon-lg" />
                                    <Feather v-else type="x" class="icon-lg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <b-form-group>
                            <b-button
                                variant="primary"
                                :disabled="loadingSave || (invalid && touched)"
                                type="submit"
                            >
                              <b-spinner v-if="loadingSave" small />
                              <span v-else>Save</span>
                            </b-button>
                          </b-form-group>
                        </form>
                      </ValidationObserver>
                    </b-tab>
                    <b-tab title="Log" @click="getReviewRequestLog">
                      <div style="margin-top: -15px">
                        <div v-if="retrieving_review_request_log" class="text-center">
                          <b-spinner small /> Retrieving Log
                        </div>
                        <div v-else>
                          <div v-if="reviewRequestLog.length < 1">
                            No Log
                          </div>
                          <b-table
                              v-else
                              :items="reviewRequestLog"
                              :fields="reviewRequestDatatable.columns"
                              :sort-by.sync="reviewRequestDatatable.queries.sort_by"
                              :sort-desc.sync="reviewRequestDatatable.queries.sort_desc"
                              :per-page="reviewRequestDatatable.queries.per_page"
                              :no-local-sorting="true"
                              :borderless="true"
                          >
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <template v-slot:cell(content)="row">
                              <!-- eslint-disable-next-line-->
                              <div v-html="row.item.nl2br_html"></div>
                            </template>
                          </b-table>
                        </div>
                        <div v-if="!retrieving_review_request_log && reviewRequestLog.length" class="row mt-4">
                          <div class="col">
                            <div class="float-left mt-2">
                              Total {{ totalReviewRequestLog }}
                            </div>
                            <div
                                class="dataTables_paginate paging_simple_numbers float-right"
                            >
                              <ul class="pagination pagination-rounded mb-0">
                                <b-pagination
                                    v-model="reviewRequestDatatable.queries.page"
                                    :total-rows="totalReviewRequestLog"
                                    :per-page="reviewRequestDatatable.queries.per_page"
                                    @change="onReviewRequestLogPageChanged"
                                ></b-pagination>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
              </b-card>
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#emails' }]">
              <div class="card">
                <div class="card-body">
                  <div v-if="!isZenSocial">
                    <ValidationObserver
                        v-slot="{ handleSubmit, invalid, touched }"
                        ref="emailsForm"
                    >
                      <form
                          class="emails-form"
                          @submit.prevent="handleSubmit(updateEmails)"
                      >
                        <div class="row">
                          <div class="col">
                            <label>Email when a lead opts-in</label>
                          </div>
                        </div>
                        <div v-if="user.has_social_review">
                          <div class="row">
                            <div class="col mb-3">
                              <h4>ReplyEngine</h4>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <TextInput
                                v-model="user.agency.settings.email_name"
                                name="email_name"
                                rules="required"
                                margin-bottom="0"
                                placeholder="From Name"
                                label="From Name"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                                v-model="user.agency.settings.email_address"
                                name="email_address"
                                rules="required|email"
                                margin-bottom="0"
                                placeholder="From Name"
                                label="From Email"
                            />
                          </div>
                        </div>
                        <div v-if="user.has_re" class="row">
                          <div class="col-md-6 mt-3">
                            <TextInput
                                v-model="user.agency.settings.email_subject"
                                name="email_subject"
                                rules="required"
                                margin-bottom="0"
                                placeholder="Subject"
                                label="Subject"
                            />
                          </div>
                        </div>
                        <div v-if="user.has_re" class="row mt-3">
                          <div class="col">
                            <vue2-tinymce-editor :id="'email_message' + user.agency.id" v-model="user.agency.settings.email_message"></vue2-tinymce-editor>
                          </div>
                        </div>
                        <div v-if="false && user.has_social_review">
                          <hr />
                          <div class="row">
                            <div class="col mb-3">
                              <h4>Social Review</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <TextInput
                                  v-model="user.agency.settings.sr_email_name"
                                  name="sr_email_name"
                                  rules="required"
                                  margin-bottom="0"
                                  placeholder="From Name"
                                  label="From Name"
                              />
                            </div>
                            <div class="col-md-6">
                              <TextInput
                                  v-model="user.agency.settings.sr_email_address"
                                  name="sr_email_address"
                                  rules="required|email"
                                  margin-bottom="0"
                                  placeholder="From Name"
                                  label="From Email"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 mt-3">
                              <TextInput
                                  v-model="user.agency.settings.sr_email_subject"
                                  name="sr_email_subject"
                                  rules="required"
                                  margin-bottom="0"
                                  placeholder="Subject"
                                  label="Subject"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <vue2-tinymce-editor :id="'sr_email_message' + user.agency.id" v-model="user.agency.settings.sr_email_message"></vue2-tinymce-editor>
                            </div>
                          </div>
                        </div>
                        <b-form-group class="text-right mt-3">
                          <b-button
                              variant="primary"
                              class="btn-dark-blue"
                              :disabled="loadingUpdate || (invalid && touched)"
                              type="submit"
                          >
                            <b-spinner v-if="loadingUpdate" small />
                            <span v-else>Update</span>
                          </b-button>
                        </b-form-group>
                      </form>
                    </ValidationObserver>
                    <ValidationObserver
                        v-slot="{ handleSubmit, invalid, touched }"
                        ref="emailsForm"
                    >
                      <form
                          class="emails-form"
                          @submit.prevent="handleSubmit(updateLMEmails)"
                      >
                        <div v-if="user.has_lead_machine">
                          <hr />
                          <div class="row">
                            <div class="col mb-3">
                              <h4>Lead Machine</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <TextInput
                                  v-model="user.agency.settings.lm_email_name"
                                  name="lm_email_name"
                                  rules="required"
                                  margin-bottom="0"
                                  placeholder="From Name"
                                  label="From Name"
                              />
                            </div>
                            <div class="col-md-6">
                              <TextInput
                                  v-model="user.agency.settings.lm_email_address"
                                  name="lm_email_address"
                                  rules="required|email"
                                  margin-bottom="0"
                                  placeholder="From Name"
                                  label="From Email"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 mt-3">
                              <TextInput
                                  v-model="user.agency.settings.lm_email_subject"
                                  name="lm_email_subject"
                                  rules="required"
                                  margin-bottom="0"
                                  placeholder="Subject"
                                  label="Subject"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <vue2-tinymce-editor :id="'lm_email_message' + user.agency.id" v-model="user.agency.settings.lm_email_message"></vue2-tinymce-editor>
                            </div>
                          </div>
                        </div>
                        <b-form-group class="text-right mt-3">
                          <b-button
                              variant="primary"
                              class="btn-dark-blue"
                              :disabled="savingLMEmail || (invalid && touched)"
                              type="submit"
                          >
                            <b-spinner v-if="savingLMEmail" small />
                            <span v-else>Update</span>
                          </b-button>
                        </b-form-group>
                      </form>
                    </ValidationObserver>
                  </div>
                  <div v-else>
                    <ValidationObserver
                        v-slot="{ handleSubmit, invalid, touched }"
                        ref="emailsForm"
                    >
                      <form
                          class="emails-form"
                          @submit.prevent="handleSubmit(updateZenEmails)"
                      >
                        <div >
                          <div class="row">
                            <div class="col-md-6">
                              <TextInput
                                  v-model="user.agency.settings.zen_email_name"
                                  name="From Name"
                                  margin-bottom="0"
                                  placeholder="From Name"
                                  label="From Name"
                              />
                            </div>
                            <div class="col-md-6">
                              <TextInput
                                  v-model="user.agency.settings.zen_email_address"
                                  name="Email Address"
                                  rules="required|email"
                                  margin-bottom="0"
                                  placeholder="From Name"
                                  label="From Email"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 mt-3">
                              <TextInput
                                  v-model="user.agency.settings.zen_email_subject"
                                  name="Subject"
                                  rules="required"
                                  margin-bottom="0"
                                  placeholder="Subject"
                                  label="Subject"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <vue2-tinymce-editor :id="'lm_email_message' + user.agency.id" v-model="user.agency.settings.zen_email_message"></vue2-tinymce-editor>
                            </div>
                          </div>
                        </div>
                        <b-form-group class="text-right mt-3">
                          <b-button
                              variant="primary"
                              class="btn-dark-blue"
                              :disabled="savingZenEmail || (invalid && touched)"
                              type="submit"
                          >
                            <b-spinner v-if="savingZenEmail" small />
                            <span v-else>Update</span>
                          </b-button>
                        </b-form-group>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isZenSocial" :class="['tab-pane', { 'active': $route.hash === '#smtp' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit }"
                      ref="smtpForm"
                  >
                    <form
                        class="smtp-form"
                        @submit.prevent="handleSubmit(updateSmtp)"
                    >
                      <div class="row">
                        <div class="col-12">
                          <TextInput
                              v-model="user.agency.smtp.host"
                              name="host"
                              rules="required"
                              margin-bottom="0"
                              placeholder="Host"
                              label="Host"
                          />
                        </div>
                        <div class="col-12 mt-3">
                          <TextInput
                              v-model="user.agency.smtp.port"
                              name="port"
                              rules="required"
                              margin-bottom="0"
                              placeholder="Port"
                              label="Port"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mt-3">
                          <TextInput
                              v-model="user.agency.smtp.username"
                              name="username"
                              rules="required"
                              margin-bottom="0"
                              placeholder="Username"
                              label="Username"
                          />
                        </div>
                        <div class="col-12 mt-3">
                          <TextInput
                              v-model="user.agency.smtp.password"
                              type="password"
                              name="password"
                              rules="required"
                              margin-bottom="0"
                              placeholder="Password"
                              label="Password"
                          />
                        </div>
                        <div class="col-12 mt-3">
                          <label>Encryption</label>
                          <select v-model="user.agency.smtp.encryption" name="encryption" class="form-control">
                            <option value=""></option>
                            <option value="tls">tls</option>
                            <option value="ssl">ssl</option>
                          </select>
                        </div>
                        <div class="col-12 mt-3">
                          <TextInput
                              v-model="user.agency.smtp.from_name"
                              name="from_name"
                              rules="required"
                              margin-bottom="0"
                              placeholder="From Name"
                              label="From Name"
                          />
                        </div>
                        <div class="col-12 mt-3">
                          <TextInput
                              v-model="user.agency.smtp.from_email"
                              name="from_email"
                              rules="required"
                              margin-bottom="0"
                              placeholder="From Email"
                              label="From Email"
                          />
                        </div>
<!--                        <div class="col-12 mt-3">
                          <TextInput
                              v-model="user.agency.smtp.encryption"
                              name="password"
                              margin-bottom="0"
                              placeholder="Encryption"
                              label="Encryption"
                          />
                        </div>-->
                      </div>
                  <b-form-group class="float-right text-right mt-3">
                    <b-button
                        variant="primary"
                        class="btn-dark-blue"
                        :disabled="loadingUpdate"
                        type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                  </form>
                  </ValidationObserver>
                  <ValidationObserver
                      v-slot="{ handleSubmit }"
                      ref="smtpForm"
                  >
                    <form
                        class="smtp-form"
                        @submit.prevent="handleSubmit(deleteSmtp)"
                    >
                      <b-form-group class="float-left text-right mt-3">
                        <b-button
                            variant="danger"
                            class="danger"
                            :disabled="loadingUpdate"
                            type="submit"
                        >
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Delete</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#privacy' }]">
              <div class="card">
                <div class="card-body">
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="privacyForm"
                  >
                    <form
                        class="privacy-form"
                        @submit.prevent="handleSubmit(updatePrivacy)"
                    >
                      <div class="row">
                        <div class="col">
                          <label>
                            Privacy Policy
                          </label>
                          <vue2-tinymce-editor id="privacy-policy" v-model="user.agency.settings.privacy_policy"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <label>
                            Terms Of Service
                          </label>
                          <vue2-tinymce-editor v-if="privacyReady" :id="'terms-of-service' + user.agency.id" v-model="user.agency.settings.terms_of_service"></vue2-tinymce-editor>
                        </div>
                      </div>
                      <b-form-group class="text-right mt-3">
                        <b-button
                            variant="primary"
                            class="btn-dark-blue"
                            :disabled="loadingUpdate || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Update</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <div :class="['tab-pane', { 'active': $route.hash === '#billing' }]">
              <div class="card">
                <div class="card-body">
                  <SettingsPaymentMethods />
                </div>
              </div>
            </div>
            <div v-if="false && hasPremiumPlan" :class="['tab-pane', { 'active': $route.hash === '#limits' }]">
              <div class="card">
                <div class="card-body">
                  <table>
                    <tbody>
                      <tr v-if="user.has_re">
                        <td class="align-middle">Monthly Reply Limit</td>
                        <td><input type="number" class="form-control"></td>
                      </tr>
                      <tr v-if="user.has_te">
                        <td class="align-middle">Employee Limit</td>
                        <td><input type="number" class="form-control"></td>
                      </tr>
                      <tr v-if="user.has_social_review">
                        <td class="align-middle">Monthly Post Limit</td>
                        <td><input type="number" class="form-control"></td>
                      </tr>
                      <tr v-if="user.has_social_fusion">
                        <td class="align-middle">Monthly Post Limit</td>
                        <td><input type="number" class="form-control"></td>
                      </tr>
                      <tr v-if="user.has_review_requester">
                        <td class="align-middle">Monthly Text Message Limit</td>
                        <td><input type="number" class="form-control"></td>
                      </tr>
                      <tr v-if="user.has_review_requester">
                        <td class="align-middle">Monthly Email Limit</td>
                        <td><input type="number" class="form-control"></td>
                      </tr>
                      <tr>
                        <td class="align-middle">User Limit</td>
                        <td><input type="number" class="form-control"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SettingsPaymentMethods from "@components/Agency/PaymentMethods/SettingsPaymentMethods";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import PasswordReset from "@components/Common/PasswordReset";
import WebHook from "@components/Common/WebHook";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Countries from "@src/countries";
import WhiteLabelDomain from "@components/Common/WhiteLabelDomain.vue";
import ManageTeams from "@components/Common/ManageTeams.vue";
export default {
  components:{
    ManageTeams,
    WhiteLabelDomain,
    SettingsPaymentMethods,
    Vue2TinymceEditor,
    PasswordReset,
    WebHook,
    VuePhoneNumberInput
  },
  data() {
    return {
      aiBusy:false,
      aiDisabled:true,
      needsAddress:true,
      showVideoModal: false,
      loadingUpdate: false,
      loadingRemove: null,
      showDomainModal: false,
      privacyReady:false,
      phoneError:false,
      isMounted:false,
      user: {
        phone_number:'',
        agency: {
          whitelabel: {},
          settings:{},
          smtp:{},
          twilio_settings:{}
        },
        settings:{
          misc:{
            dashboard_reply_engine_plan:'',
            dashboard_tap_engine_plan:''
          }
        }
      },
      rawSID: '',
      rawToken: '',
      retrieving_review_request_log:false,
      loadingSave:false,
      savingLMEmail:false,
      savingZenEmail:false,
      isDisabled:true,
      reviewRequestDatatable: {
        items: [],
        columns: [
          { key: 'type', label:'Type', sortable: true },
          { key: 'front_date', label:'Sent Date', sortable: true},
          { key: 'sent_to', label:'Sent To' },
          { key: 'content', label:'Content'},
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'created_at',
          sort_desc: false,
        },
      }
    }
  },
  computed:{
    hasPremiumPlan(){
      return this.user.has_premium_plan || this.isAdmin;
    },
    isAdmin(){
      return localStorage.getItem('main_access_role') === 'Admin';
    },
    formattedAIPassword:{
      get(){
        return '*'.repeat(this.user.agency.settings.openai_api_key?.length - 7) + this.user.agency.settings.openai_api_key?.slice(-7);
      },
      set(value){
      }
    },
    formattedTwilioSID:{
      get(){
        if(this.user.agency.twilio_settings?.twilio_sid && this.user.agency.twilio_settings?.twilio_sid.length > 7) {
          return '*'.repeat(this.user.agency.twilio_settings.twilio_sid?.length - 7) + this.user.agency.twilio_settings.twilio_sid?.slice(-7);
        }else{
          return this.user.agency.twilio_settings?.twilio_sid;
        }
      },
      set(value){
        this.rawSID = value;
      }
    },
    formattedTwilioToken:{
      get(){
        if(this.user.agency.twilio_settings?.twilio_token && this.user.agency.twilio_settings?.twilio_token.length > 7) {
          return '*'.repeat(this.user.agency.twilio_settings.twilio_token?.length - 7) + this.user.agency.twilio_settings.twilio_token?.slice(-7);
        }else{
          return this.user.agency.twilio_settings?.twilio_token;
        }
      },
      set(value){
        this.rawToken = value;
      }
    },
    formattedSengridApiKey:{
      get(){
        if(this.user.agency.twilio_settings?.sendgrid_api_key && this.user.agency.twilio_settings?.sendgrid_api_key.length > 7) {
          return '*'.repeat(this.user.agency.twilio_settings.sendgrid_api_key?.length - 7) + this.user.agency.twilio_settings.sendgrid_api_key?.slice(-7);
        }else{
          return this.user.agency.twilio_settings?.sendgrid_api_key;
        }
      },
      set(value){
        this.user.agency.twilio_settings.sendgrid_api_key = value;
      }
    },
    defaultCountryCode(){
      return this.user.agency?.twilio_settings?.phoneObject?.countryCode ?? 'US';
    },
    reviewRequestLog(){
      return this.$store.getters['reviewRequestLog/all'];
    },
    totalReviewRequestLog(){
      return this.$store.getters['reviewRequestLog/total'];
    },
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.setupPrivacy();
    this.isMounted = true;
  },

  methods: {
    updateOpenAiAPIKey(){
      this.aiBusy = true

      let params = {
        openai_api_key: this.user.agency.settings.openai_api_key,
        setting_form:'openai'
      }

      this.$store
        .dispatch('agency/editSettings', params)
        .then(() => {
          this.aiBusy = false
          this.aiDisabled = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors);
          }
          this.aiBusy = false
        })
    },
    phoneUpdated(phone){
      this.user.agency.twilio_settings.phoneObject = phone;
    },
    onReviewRequestLogPageChanged(page) {
      this.reviewRequestDatatable.queries.page = page
      this.getReviewRequestLog();
    },
    getReviewRequestLog(){
      this.retrieving_review_request_log = true;
      this.$store
          .dispatch(`reviewRequestLog/getAll`, {
            queries: this.reviewRequestDatatable.queries,
            page: 'logs',
            businessId:null,
            agencyId:this.user.agency.id
          })
          .then((resp) => {
            this.retrieving_review_request_log = false;
          })
          .catch((err) => {
            this.retrieving_review_request_log = false;
            console.error(err);
          })
    },
    Countries() {
      return Countries
    },
    setPhone(phone){
      // this.userPhone = phone.formatInternational;
    },
    async setupPrivacy() {
      // this is stupid but tinymce is creating muliple editors with the same id because they are init at the same time
      await new Promise(resolve => setTimeout(resolve, 500));
      this.privacyReady = true;
    },
    openVideoModal() {
      this.showVideoModal = true
    },

    updateProfile() {
      this.loadingUpdate = true
      this.phoneError = false;
      let params = {
        company_name: this.user.agency.name,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone_number,
        email: this.user.email,
        address:this.user.address,
        notify_initial_searches:this.user.agency.settings.notify_initial_searches,
        notify_new_leads:this.user.agency.settings.notify_new_leads,
        powered_by_link:this.user.agency.settings.powered_by_link,
        setting_form:'settings'
      }

      this.$store
        .dispatch('agency/editSettings', params)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors);
            if(Object.keys(err.response.data.errors).includes('phone')){
              this.phoneError = true;
            }
          }
          this.loadingUpdate = false
        })
    },
    updatePrivacy(){
      this.loadingUpdate = true

      let params = {
        privacy_policy: this.user.agency.settings.privacy_policy,
        terms_of_service: this.user.agency.settings.terms_of_service,
        setting_form:'privacy'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    updateSmtp(){
      this.loadingUpdate = true

      let params = {
        host: this.user.agency.smtp.host,
        port: this.user.agency.smtp.port,
        username: this.user.agency.smtp.username,
        password: this.user.agency.smtp.password,
        encryption: this.user.agency.smtp.encryption ?? null,
        from_email: this.user.agency.smtp.from_email,
        from_name: this.user.agency.smtp.from_name,
        setting_form:'smtp'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then((res) => {
            this.user.agency.smtp = res.agency.smtp;
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    deleteSmtp(){
      this.loadingUpdate = true

      let params = {
        user_id: this.user.agency.ower_id,
        setting_form:'delete_smtp'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then((res) => {
            console.log(res);
            this.user.agency.smtp = res.agency.smtp;
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    updateZenEmails(){
      this.savingZenEmail = true

      let params = {
        zen_email_name: this.user.agency.settings.zen_email_name,
        zen_email_address: this.user.agency.settings.zen_email_address,
        zen_email_message: this.user.agency.settings.zen_email_message,
        zen_email_subject: this.user.agency.settings.zen_email_subject,
        setting_form:'zen_emails'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.savingZenEmail = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.savingZenEmail = false
          })
    },
    updateLMEmails(){
      this.savingLMEmail = true

      let params = {
        lm_email_name: this.user.agency.settings.lm_email_name,
        lm_email_address: this.user.agency.settings.lm_email_address,
        lm_email_message: this.user.agency.settings.lm_email_message,
        lm_email_subject: this.user.agency.settings.lm_email_subject,
        setting_form:'lm_emails'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.savingLMEmail = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.savingLMEmail = false
          })
    },
    updateEmails(){
      this.loadingUpdate = true

      let params = {
        email_name: this.user.agency.settings.email_name,
        email_address: this.user.agency.settings.email_address,
        email_message: this.user.agency.settings.email_message,
        email_subject: this.user.agency.settings.email_subject,
        setting_form:'emails'
      }

      this.$store
          .dispatch('agency/editSettings', params)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.privacyForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    },
    handleExplain() {
      this.showDomainModal = true
    },
    updateTwilioSettings(){
      this.loadingSave = true

      const requestParam = {
        agency_id: this.user.agency.id,
        settings: this.user.agency.twilio_settings
      }
      console.log(requestParam)
      this.$store
          .dispatch('agency/editTwilioSettings', requestParam)
          .then(() => {
            this.loadingSave = false;
          })
          .catch((err) => {
            console.error(err);
            this.loadingSave = false
          })
    },
  },
}
</script>

<style lang="scss">
.settingsPage {
  .boxFile {
    .input-group {
      background-color: #4284f4;
      border-color: 1px solid #4284f4;
      border-radius: 0.3rem;
      color: #fff;
      input[type='file'] {
        cursor: pointer;
      }
      &::before {
        position: absolute;
        top: 9px;
        left: calc(50% - 45px);
        font-weight: 600;
        color: #fff;
        pointer-events: none;
        content: 'Upload File';
      }
      &:hover {
        background-color: #1e6cf2;
        border-color: #1265f1;
        &::before {
          color: #fff;
        }
      }
    }
    .form-control {
      opacity: 0;
    }
  }
}
#MazPhoneNumberInput {
  .select-country-container{
    flex: 0 0 85px;
    width: 85px;
    min-width: 85px;
    max-width: 85px;
  }
  label.input-tel__label, label.country-selector__label {
    color: #4b4b5a !important;
    font-weight: normal;
  }
}
</style>
